import React from "react";
import { ListadoProyectos } from "./ListadoProyectos";


export const Portafolio = () => {
  return (
    <>
      <h2 className="titulo-proyects-portafolio">Mis proyectos</h2>
      <ListadoProyectos/>
    </>
  );
};
