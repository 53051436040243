//import logo from './logo.svg';
import './App.css';
import { RouterPrincipal } from './routes/RouterPrincipal';

function App() {
  return (
    <div className="layout">
      <RouterPrincipal/>
    </div>
    
  );
}

export default App;
